
.tableContainer {
  position: relative;
  height: calc(calc(var(--vh, 1vh) * 100) - 64px);
  overflow: auto;
  right: 0;
}

.table{
  position: absolute;
  left: 0;
  right: 0;
}