:root {
  --primary-color: "white";
  --secondary-color: "black";
}

.statistics__wrapper {
  display: grid;
  grid-template-columns: 50% 50%;
  /* height: 200px; */
}

#filter, #shareButton {
  line-height: 0px;
}

#reportingWrapper .ant-progress-inner {
  width: 10vh !important;
  height: 10vh !important;
}

@font-face {
  font-family: 'Poppins-Regular';
  src: url('../fonts/Poppins-Regular.eot');
  src: url('../fonts/Poppins-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-Regular.svg#Poppins-Regular') format('svg'), url('../fonts/Poppins-Regular.ttf') format('truetype'), url('../fonts/Poppins-Regular.woff') format('woff'),
    url('../fonts/Poppins-Regular.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Poppins-Bold';
  src: url('../fonts/Poppins-Bold.eot');
  src: url('../fonts/Poppins-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/Poppins-Bold.svg#Poppins-Bold') format('svg'), url('../fonts/Poppins-Bold.ttf') format('truetype'), url('../fonts/Poppins-Bold.woff') format('woff'),
    url('../fonts/Poppins-Bold.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}

.contentWrapper div {
  font-family: 'Poppins-Bold', sans-serif;
  font-size: calc(1.3vw + 1.3vh + 1vmin);
  margin-left: 30px;
  margin-right: 30px;
  text-align: center;
}

#root {
  height: 100%;
}

* {
  box-sizing: border-box;
}

.green {
  background: green;
}

.colorTwo {
  background: white;
}

.contentWrapper {
  width: 100%;
  height: 100%;
  position: absolute;
  font-size: 20px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
}

.col-container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
}
.col {
  width: 50%;
  /* min-height: 100%; */
  /* height: 100%; */
  height: 100vh;
  height: calc(calc(var(--vh, 1vh) * 100) - 64px);
}

.secondColumn {
  overflow-y: auto;
  padding: 64px;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.category {
  margin: 0px;
  color: white !important;
  background: var(--secondary-color);
  border-color: var(--secondary-color) !important; 
  cursor: pointer;
}

.categories {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1;
  display: flex;
  flex-wrap: wrap;
  margin-left: 20px;
  gap: 8px;
  justify-content: flex-end;
  max-width: 50%;
}

.skeleton {
  width: 250px;
}

@media only screen and (max-width: 767px) {
  .secondColumn {
    padding: 24px;    
  }

}


@media only screen and (max-width: 1000px) {
  .categories {
    max-width: 100%;
  }
  .secondColumn {
    display: table;
  }
  .col-container {
    flex-direction: column;
    overflow: auto;
    height: inherit;
  }

  .col {
    width: 100%;
  }

  .col:nth-child(2) {
    overflow: initial;
  }
}

