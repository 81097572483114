.big {
  height: 46%;
  position: relative;
}

.small {
  height: 8%;
}

.gameControls {
  font-size: 10vh;
  color: black;
  cursor: pointer;
}

.gameControlsContainer {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  z-index: 1;
}

.statisticsLeft {
  top: 7vh;
  left: 20px;
}

.statisticsRight {
  bottom: 7vh;
  right: 20px;
}

.choicesCounterWrapper {
  position: absolute;
  display: flex;
  bottom: 20px;
  padding: 6px;
  left: 50%;
  z-index: 1;
  border-radius: 20px;
  border-color: white;
  border-style: solid;
  justify-content: center;
  align-items: center;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
}

.shareButton {
  position: absolute;
  z-index: 1;
  cursor: pointer;
  color: white;
  font-size: 24px;
}

.bottomLeft {
  left: 20px;
  bottom: 20px;
}

.topRight {
  right: 20px;
  top: 20px;
}

.likeButton {
  position: absolute;
  z-index: 1;
  font-size: 8vh;
  cursor: pointer;
}

.share {
  display: flex;
  align-items: center;
}

.shareIconMargin {
  margin-right: 10px;
}

#reportingWrapper {
  position: absolute;
  left: 0;
}

.statistics {
  position: absolute;
  color: white;
  fill: white;
  z-index: 1;
}
