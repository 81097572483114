  .user-interactions__wrapper {
      width: 100%;
  }



/* 
@media only screen and (min-width: 1600px) {
    .choices-interactions__wrapper {
        width: 50%;
    }
}

@media only screen and (max-width: 1000px) {
    .choices-interactions__wrapper {
        width: 100%;
    }
} */