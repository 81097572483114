.create-questions-form {
  width: 100%;
}

.create-questions--error {
  display: flex;
  flex-direction: row;
  text-align: start;
}
/* .login-form-forgot {
    float: right;
  }
  .login-form-button {
    width: 100%;
  }
   */
