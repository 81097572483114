body {
  width: 100% !important;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.ant-menu-item svg,
.ant-dropdown-menu-item svg {
  margin-right: 8px;
}
