.avatarRemove {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #282828de;
    cursor: default;
}

.avatarRemoveIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    font-size: 30px;
    color: lightgray;
    cursor: pointer;
}

.avatarRemoveIcon:hover {
    color: white
}

.ant-upload-select {
    position: relative;
}

.defaultCursor {
    cursor: default
}

.profileAvatar {
    width: 100%;
    height: 100%; 
    background-size: cover;
}