textarea {
  resize: none;
}

#commentTextArea {
  min-height: 96px !important;
  max-height: 96px !important;
  height: 96px !important;
}

.commentsAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
}

.commentsAvatar img {
  width: 100%;
  height: 100%; 
  border-radius: 0;
}

.cardWrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.cardEntry {
  width: 50%;
}

.score__tag {
  margin-left: 8px;
}


@media only screen and (min-width: 1600px) {
  #commentTextArea {
    width: 50%;
  }
}

@media only screen and (max-width: 1000px) {
  .cardWrapper {
    display: block;
  }

  .cardEntry {
    width: 100%;
  }
}
