#filter {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 1;
  font-size: 24px;
  color: white;
}

.select--wrapper .select {
  width: 100% !important;
}

.select--wrapper {
  width: 250px;
}
