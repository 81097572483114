.ant-message-custom-content {
  display: flex;
  text-align: left;
}

.ant-message-custom-content span[role='img'] {
  top: 3px;
}

.message--text {
  margin-top: -2px;
}

.filter--grayscale {
  filter: grayscale(100%);
}

.multiplayerButton {
  margin-right: 8px;
}
.logo {
  width: 180px;
  position: relative;
  margin-top: 8px;
}

.ant-layout-sider-children {
  position: relative;
}
.ant-page-header-heading-extra {
  width: auto;
  position: absolute;
  right: 0;
  padding: 0;
  top: 50%;
  transform: translateY(-50%);
}

.ant-page-header-heading {
  position: relative;
}

.ant-layout-header .ant-avatar {
  margin-right: 0px;
  border-color: #949494;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
}

.ant-layout-header .ant-avatar img {
  width: 100%;
  height: 100%;
}

.ant-layout-header .ant-avatar:hover {
  filter: grayscale(0%)
}

.avatarPlacement {
  cursor: pointer;
}

.greetingText {
  line-height: 0px;
  margin-right: 8px;
}

.header {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0;
  background: rgb(255, 255, 255) !important;
  z-index: 2;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  height: 64px;
  position: sticky;
  top: 0;
  line-height: 0px;
  padding-inline: 0px !important;

}

.menuIcon {
  position: relative;
  left: 24px;
  color: black;
  font-size: 20px;
}

.mobileNavigation {
  position: absolute;
  top: 0px;
  height: 100%;
  z-index: 2;
}

.overflowHidden {
  overflow: hidden;
}

.mobileCloseButton {
  margin-right: 5px;
  margin-top: 5px;
  cursor: pointer;
  font-size: 20px;
}

.menuWrapper {
  display: flex;
  flex-direction: column;
  height: 100vh;
  align-items: center;
  background: black;
}

.ant-menu {
  background-color: black !important;
}

.ant-layout.ant-layout-has-sider > .ant-layout,
.ant-layout.ant-layout-has-sider > .ant-layout-content {
  overflow-x: unset;
}

.ant-menu-submenu-popup {
  z-index: -1;
}


.ant-drawer {
  position: fixed;
}

.sideNavBar {
  z-index: 5;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  height: 100%;
  position: sticky;
  top: 0;
}

.sideNavBarOverlay {
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 4;
  background: rgba(0, 0, 0, 0.45);
  position: fixed;
}

.dropdownWrapper {
  display: flex;
  align-items: center;
  position: absolute;
  right: 24px;
}

.greetingTextWrapper {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 400px) {
  .greeting-message {
    width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: block;
    text-align: right;
  }
}

@media only screen and (max-width: 1000px) {
  main {
    width: 100vw;
  }

  header {
    width: 100vw;
    position: fixed !important;
  }

  .sideNavBar {
      position: fixed !important;
  }

  main.ant-layout-content {
    margin-top: 64px !important;
  }
}
